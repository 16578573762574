import React from "react";

import Navbar from "../componants/Navbar";
import Services from "../componants/Services";
import Quotes from "../componants/Quotes";
import OurServices from "../componants/OurServices";
// import Count from "../componants/Count";
import CountBack from "../componants/CountBack";


const HomeScreen = () => {
  return (
    <>
      <Navbar />
      <Services />
      <CountBack/>
      
      <OurServices />
      <Quotes />
    </>
  );
};

export default HomeScreen;
