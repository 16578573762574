import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";

const Services = () => {
  return (
    <>
      <Container className="my-5">
        <h1 className="text-center fw-bold py-3">Our Featured Services</h1>
        <p className="text-center py-3">
          At our company, we are dedicated to your personal and professional
          growth through the power of education. We understand that learning is
          a lifelong journey, and we're here to support you every step of the
          way.
        </p>

        <Row className="d-flex justify-content-center my-5 pb-5">
          <Col xs={12} md={6} lg={4} className="rounded">
            <Card className="my-3 rounded shadow"
              style={{ backgroundColor: "#e6e6e6" }}>
              <Card.Img
                className="rounded p-2"
                style={{ maxHeight: "300px" }}
                src="./image/elderly.png"
              />
              <Card.Header as="h5" className="text-center p-3 border-0 fw-bold">
                Care Services
              </Card.Header>
              <Card.Header
                className="pb-5"
                style={{ textAlign: "justify", minHeight: "240px" }}
              >
                Our Care Services for Adults are thoughtfully designed to
                provide support, care, and assistance to individuals in this
                important stage of life. Whether you or your loved one requires
                help with daily tasks, companionship, or specialized care, our
                dedicated team is here to ensure a fulfilling and comfortable
                adult life.
              </Card.Header>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={4}>
            <Card className="my-3 rounded shadow"
              style={{ backgroundColor: "#e6e6e6" }}>
              <Card.Img
                className="rounded p-2"
                style={{ maxHeight: "300px" }}
                src="./image/management.png"
              />
              <Card.Header as="h5" className="text-center p-3 border-0 fw-bold">
                Recruitment Services
              </Card.Header>
              <Card.Header
                className="pb-5"
                style={{ textAlign: "justify", minHeight: "240px" }}
              >
                We understand the vital role that skilled professionals play in
                shaping the future. Our "Recruitment Services" division is
                dedicated to connecting talent with opportunity, helping
                individuals and organizations thrive in today's dynamic job
                market.
              </Card.Header>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={4}>
            <Card className="my-3 rounded shadow" style={{ backgroundColor: "#e6e6e6" }}>
              <Card.Img
                className="rounded p-2"
                style={{ maxHeight: "300px" }}
                src="./image/leadership.png"
              />
              <Card.Header as="h5" className="text-center p-3 border-0 fw-bold">
                Consultancy Services
              </Card.Header>
              <Card.Header
                className="pb-5"
                style={{ textAlign: "justify", minHeight: "240px" }}
              >
                We believe in the power of informed decisions and strategic
                guidance. Our "Consultancy Services" division is dedicated to
                helping businesses and individuals unlock their full potential,
                navigate challenges, and achieve success through expert
                consultation and support.
              </Card.Header>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={4}>
            <Card className="my-3 rounded shadow"
              style={{ backgroundColor: "#e6e6e6" }}>
              <Card.Img
                className="rounded p-2"
                style={{ maxHeight: "300px" }}
                src="./image/foodbank.png"
              />
              <Card.Header as="h5" className="text-center p-3 border-0 fw-bold">
                Charity and Foundation
              </Card.Header>
              <Card.Header
                className="pb-5"
                style={{ textAlign: "justify", minHeight: "240px" }}
              >
                At our heart, we are a philanthropic organization committed to
                making a positive impact on society. We believe in the power of
                compassion and collective effort to bring about meaningful
                change. Our mission encompasses a wide range of initiatives
                aimed at addressing various social issues and improving the
                lives.
              </Card.Header>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={4}>
            <Card className="my-3 rounded shadow"
              style={{ backgroundColor: "#e6e6e6" }}>
              <Card.Img
                className="rounded p-2"
                style={{ maxHeight: "300px" }}
                src="./image/international.png"
              />
              <Card.Header as="h5" className="text-center p-3 border-0 fw-bold">
                Education and Training
              </Card.Header>
              <Card.Header
                className="pb-5"
                style={{ textAlign: "justify", minHeight: "250px" }}
              >
                At our company, we are deeply passionate about the
                transformative power of education and skill development. We
                believe that learning is a lifelong journey that can unlock
                unlimited potential. We are committed to providing comprehensive
                support and resources to help individuals excel in their
                educational and professional pursuits.
              </Card.Header>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Services;
