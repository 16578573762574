import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const RecruitmentAgency = () => {
  return (
    <Container className="mt-5 pt-5">
      <Row className="my-4">
        <h2 className="text-center mb-4">Recruitment Services</h2>
        <p className="mt-2 mb-5" style={{ textAlign: "justify" }}>
          We understand the vital role that skilled professionals play in
          shaping the future. Our "Recruitment Services" division is dedicated
          to connecting talent with opportunity, helping individuals and
          organizations thrive in today's dynamic job market.
          <br />
          Explore our comprehensive range of recruitment services, each designed
          to meet the unique needs of diverse industries. Whether you're seeking
          core staff, IT experts, effective management personnel, or specialized
          professionals, our team is committed to finding the perfect match for
          your organization.
        </p>
      </Row>
      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/core.png"
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Core Services</h3>
            <p style={{ textAlign: "justify" }}>
              The heart of any organization lies in its team. Our "Core
              Services" within our Recruitment division are dedicated to
              identifying and connecting you with the fundamental talent that
              drives your business forward and finding you the best and suitable
              team you need.
              <br />
              We specialize in finding individuals who embody your company's
              values, culture, and vision. Whether you need skilled
              professionals, entry-level talent, or experienced leaders, our
              recruitment experts meticulously evaluate candidates to ensure
              they align with your organization's core objectives.
              <br />
              Discover how our "Core Recruitment Services" can help you secure
              the talent you need to thrive in today's competitive business
              landscape.
            </p>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Internet of Things (IoT)</h3>
            <p style={{ textAlign: "justify" }}>
              In today's digital age, the Internet of Things (IoT) has
              transformed the way we interact with the world. Finding the right
              talent to drive innovation in IoT technologies is essential. At
              [College Name], our specialized "IoT Recruitment Services" are
              designed to help organizations harness the power of IoT and propel
              their projects forward.
              <br />
              Our dedicated team of recruiters understands the unique skills and
              expertise required in the IoT landscape. Whether you're seeking
              IoT developers, data analysts, cybersecurity experts, or IoT
              project managers, we have the network and experience to connect
              you with top talent.
              <br />
              We're committed to helping organizations stay ahead in the IoT
              race and supporting professionals in finding opportunities that
              align with their IoT ambitions. Explore how our "IoT Recruitment
              Services" can help you unlock the potential of the Internet of
              Things.
            </p>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/iot.png"
          />
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/management.png"
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Management</h3>
            <p style={{ textAlign: "justify" }}>
              In today's competitive business landscape, effective leadership is
              the cornerstone of success. Our "Management Recruitment Services"
              are designed to help organizations identify and secure top-tier
              management talent. Whether you're searching for experienced
              executives, middle managers, or leaders with niche expertise, we
              have the skills and network to match your needs.
              <br />
              Our approach combines industry insights, rigorous evaluation, and
              a commitment to understanding your organization's unique culture
              and goals. We're dedicated to finding candidates who not only meet
              the technical qualifications but also align with your values and
              vision.
              <br />
              Discover how our "Management Recruitment Services" can strengthen
              your leadership team and propel your business forward.
            </p>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Other Service</h3>
            <p style={{ textAlign: "justify" }}>
              In addition to our core recruitment offerings, we provide a
              diverse range of recruitment services designed to meet unique
              staffing and talent needs. We understand that the modern job
              market demands flexibility and adaptability, and our specialized
              services cater to various industries and niches.
              <br />
              Whether your organization requires specialized consulting, niche
              talent acquisition, or unique staffing solutions, our team of
              experts is well-equipped to navigate the complexities of your
              industry. We pride ourselves on our ability to think outside the
              box, finding innovative ways to connect exceptional candidates
              with organizations seeking top-tier talent.
              <br />
              Reach out to us for any recruitment services you need. And we will
              be happy to help you.
            </p>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/other.png"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default RecruitmentAgency;
