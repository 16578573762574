import React from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

const Contact = () => {
  return (
    <>
      <h2
        className="my-5 pt-5 pb-3 text-center"
        style={{ backgroundColor: "#e6e6e6" }}
      >
        Contact Us
      </h2>

      <Container>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <h4 className="mb-4">SEND US A MESSAGE</h4>
            <Form>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label className="">Your Name</Form.Label>
                <Form.Control
                  className="rounded"
                  required
                  type="text"
                  placeholder="Enter your name"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label className="">Email</Form.Label>
                <Form.Control
                  className="rounded"
                  required
                  type="text"
                  placeholder="Enter your Email"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="subject">
                <Form.Label className="">Subject</Form.Label>
                <Form.Control
                  className="rounded"
                  required
                  type="text"
                  placeholder="Specify the subject for reaching out"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="message">
                <Form.Label className="">Message</Form.Label>
                <Form.Control
                  className="rounded"
                  required
                  type="text"
                  as="textarea"
                  rows={5}
                  placeholder="Type your message here"
                />
              </Form.Group>

              <Button
                variant="outline-primary"
                type="submit"
                className="shadow rounded px-4 py-2"
              >
                Submit
              </Button>
            </Form>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <h4 className="mb-4">OUR LOCATION</h4>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d622.797031459771!2d-0.09698007135447621!3d51.36283815530647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876074b886af777%3A0x96e8c191cf17dca9!2s20C%20Selsdon%20Rd%2C%20Croydon%2C%20South%20Croydon%20CR2%206PA%2C%20UK!5e0!3m2!1sen!2sbd!4v1695798391460!5m2!1sen!2sbd"
              width="600"
              height="470"
              className="rounded border-0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>

        <Row className="my-5 pe-3">
          <Col lg={6} md={6} sm={12}>
            <h4 className="mb-5">CONTACT INFORMATION</h4>
            <Row className="mb-2">
              <p className="fw-bold">
                Call on the number below or email us for any queries. We will
                get back to you as soon as possible.
              </p>
            </Row>
            {/* <Row className="mb-2">
              <p>
                Lorem ipsum dolor sit amet, cadipisicing sit amet, consectetur
                adipisicing elit. Lorem ipsum dolor sit amet, cadipisicing sit
                amet, consectetur adipisicing elit. Atque sed, quidem quis
                praesentium Atque sed, quidem quis praesentium, ut unde fuga
                error commodi architecto, laudantium culpa tenetur at id, beatae
                pet.
              </p>
            </Row> */}
            <Row className="">
              <p>Phone : +44 2035817258</p>
              <p>Email : gaps@testmail.com</p>
            </Row>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <h4 className="mb-5">ADDRESS</h4>
            <Row className="">
              <p>
                Graduate And Professional Services (GAPS).<br/>
                20C(B) Selsdon Road<br/>
                Croydon<br/>
                London<br/>
                United Kingdom<br/>
                CR2 6PA
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
