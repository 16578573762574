import React from "react";
import CountUp from "react-countup";
import { Container, Row, Col } from "react-bootstrap";

const CountBack = () => {
  return (
    <div
      style={{ minHeight: "18vh", backgroundColor: "#e6e6e6" }}
      className="d-flex text-center align-items-center justify-content-center py-4"
    >
      <Container>
        <Row>
          <Col
            lg={4}
            md={4}
            sm={12}
            className="py-4">

            <Row>
              <h2 className="text-dark">
                Recruitment
              </h2>
            </Row>

            <Row className="flex">
              <Col className="d-flex align-items-center justify-content-end pe-0">
              <CountUp
                start={0}
                end={15}
                duration="3"
                style={{ fontSize: "30px"}}
              />
              </Col>
              <Col className="d-flex align-items-center justify-content-start ps-0">
              <span
                style={{ fontSize: "30px"}}
              >+</span>
              </Col>
            </Row>
          </Col>

          <Col
            lg={4}
            md={4}
            sm={12}
            className="py-4">

            <Row>
              <h2 className="text-dark">
                Recruitment
              </h2>
            </Row>

            <Row className="flex">
              <Col className="d-flex align-items-center justify-content-end pe-0">
              <CountUp
                start={0}
                end={1000}
                duration="3"
                style={{ fontSize: "30px"}}
              />
              </Col>
              <Col className="d-flex align-items-center justify-content-start ps-0">
              <span
                style={{ fontSize: "30px"}}
              >+</span>
              </Col>
            </Row>
          </Col>

          <Col
            lg={4}
            md={4}
            sm={12}
            className="py-4">

            <Row>
              <h2 className="text-dark">
              Satisfied Client
              </h2>
            </Row>

            <Row className="flex">
              <Col className="d-flex align-items-center justify-content-end pe-0">
              <CountUp
                start={0}
                end={20}
                duration="3"
                style={{ fontSize: "30px"}}
              />
              </Col>
              <Col className="d-flex align-items-center justify-content-start ps-0">
              <span
                style={{ fontSize: "30px"}}
              >+</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CountBack;
