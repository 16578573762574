import React, { useEffect, useState } from "react";
import { Container, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Header = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);

  return (
    <Container>
      {isMobile ? (
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="info"
        data-bs-theme="dark"
        className="fixed-top"
        variant="light"
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                style={{ maxWidth: "100px", maxHeight: "90px" }}
                src="/image/logo.png"
              />
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto align-items-center pt-2">
              <LinkContainer to="/">
                <Nav.Link href="#">HOME</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/aboutUs">
                <Nav.Link>ABOUT US</Nav.Link>
              </LinkContainer>

              <NavDropdown
              className="text-center"
                  title={
                    <>
                      GRADUATE & PROFESSIONAL
                      <br />
                      SERVICES
                    </>
                  }
                id="collasible-nav-dropdown"
              >
                <LinkContainer to="/careServices">
                  <NavDropdown.Item>Care Services</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/recruitmentAgency">
                  <NavDropdown.Item>Recruitment Agency</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/consultancyServices">
                  <NavDropdown.Item>Consultancy Services</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/churchCharity">
                  <NavDropdown.Item>Church and Charity Services</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
                <NavDropdown
                  className="text-center"
                  title={
                    <>
                      GRADUATE & PROFESSIONAL <br/> STUDIES INTERNATIONAL
                    </>
                  }
                  id="collasible-nav-dropdown"
                >
                <LinkContainer to="/educationTraining">
                  <NavDropdown.Item>Education & Training</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <LinkContainer to="/contact">
                <Nav.Link>CONTACT</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      ) : (
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="info"
            data-bs-theme="dark"
            className="fixed-top"
            variant="light"
          >
            <Container>
              <LinkContainer to="/">
                <Navbar.Brand>
                  <img
                    style={{ maxWidth: "100px", maxHeight: "90px" }}
                    src="/image/logo.png"
                  />
                </Navbar.Brand>
              </LinkContainer>

              <Navbar.Toggle aria-controls="responsive-navbar-nav" />

              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto align-items-center pt-2">
                  <LinkContainer to="/">
                    <Nav.Link href="#">HOME</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/aboutUs">
                    <Nav.Link>ABOUT US</Nav.Link>
                  </LinkContainer>

                  <NavDropdown
                    title="GRADUATE & PROFESSIONAL SERVICES"
                    id="collasible-nav-dropdown"
                  >
                    <LinkContainer to="/careServices">
                      <NavDropdown.Item>Care Services</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/recruitmentAgency">
                      <NavDropdown.Item>Recruitment Agency</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/consultancyServices">
                      <NavDropdown.Item>Consultancy Services</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/churchCharity">
                      <NavDropdown.Item>Church and Charity Services</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>

                  <NavDropdown
                    title="GRADUATE & PROFESSIONAL STUDIES INTERNATIONAL"
                    id="collasible-nav-dropdown"
                  >
                    <LinkContainer to="/educationTraining">
                      <NavDropdown.Item>Education & Training</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>

                  <LinkContainer to="/contact">
                    <Nav.Link>CONTACT</Nav.Link>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
      )}
    </Container>
  );
};

export default Header;
