import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const ChurchAndCharity = () => {
  return (
    <Container className="mt-5 pt-5">
      <Row className="my-4">
        <h2 className="text-center mb-4">Charity and Foundation</h2>
        <p className="mt-2 mb-5" style={{ textAlign: "justify" }}>
          At our heart, we are a philanthropic organization committed to making
          a positive impact on society. We believe in the power of compassion
          and collective effort to bring about meaningful change. Our mission
          encompasses a wide range of initiatives aimed at addressing various
          social issues and improving the lives of those in need. Here's a
          closer look at some of our core areas of focus:
        </p>
      </Row>
      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/homeless.png"
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Support Homeless</h3>
            <p style={{ textAlign: "justify" }}>
              Our commitment to supporting homeless individuals and families
              extends beyond the provision of immediate relief. We believe in
              offering holistic assistance that includes safe shelter,
              nutritious meals, access to healthcare, and opportunities for
              skill development. By addressing the root causes of homelessness
              and providing a pathway to stability, we strive to restore dignity
              and hope to those experiencing homelessness.
            </p>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Food Banks</h3>
            <p style={{ textAlign: "justify" }}>
              Hunger is a pressing issue that affects communities worldwide. Our
              food banks play a pivotal role in alleviating this suffering by
              sourcing, storing, and distributing food to those facing food
              insecurity. We collaborate with local farmers, businesses, and
              volunteers to ensure that nutritious meals reach the tables of
              individuals and families in need, fostering healthier and more
              resilient communities.
            </p>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/foodbank.png"
          />
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/counselling.png"
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Advice & Counselling</h3>
            <p style={{ textAlign: "justify" }}>
              Life can present us with challenges that are difficult to navigate
              alone. Our dedicated team of counselors and advisors are here to
              provide a supportive and empathetic environment for individuals
              seeking guidance. Whether it's coping with mental health issues,
              managing personal crises, or making important life decisions, we
              offer professional advice and counseling services to empower
              individuals to overcome obstacles and lead fulfilling lives.
            </p>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Underdeveloped</h3>
            <p style={{ textAlign: "justify" }}>
              We are deeply committed to fostering sustainable development in
              underprivileged and underdeveloped regions. Our multifaceted
              approach involves investing in education, healthcare,
              infrastructure, and economic empowerment. By collaborating with
              local communities and organizations, we aim to create lasting
              change that empowers residents, promotes self-sufficiency, and
              unlocks the potential of these marginalized areas.
            </p>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/development.png"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ChurchAndCharity;
