import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const EducationAndTraining = () => {
  return (
    <Container className="mt-5 pt-5">
      <Row className="my-4">
        <h2 className="text-center mb-4">Education and Training</h2>
        <p className="mt-2 mb-5" style={{ textAlign: "justify" }}>
          At our company, we are deeply passionate about the transformative
          power of education and skill development. We believe that learning is
          a lifelong journey that can unlock unlimited potential. We are
          committed to providing comprehensive support and resources to help
          individuals excel in their educational and professional pursuits. Here
          are two key areas where we specialize:
        </p>
      </Row>
      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/graduate.png"
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Graduate and professional Studies</h3>
            <p style={{ textAlign: "justify" }}>
              Our expertise lies in guiding individuals through the enriching
              journey of graduate and professional studies. Whether you're
              aiming to pursue a master's degree, a doctoral program, or
              specialized professional training, our dedicated team is here to
              assist you at every step. We offer personalized admissions
              counseling, tailored test preparation, academic mentoring, and
              career development resources. With our support, you can navigate
              the complex world of higher education with confidence, paving the
              way for a successful and fulfilling academic journey.
            </p>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">International Studies</h3>
            <p style={{ textAlign: "justify" }}>
              Embracing the global perspective is crucial in today's
              interconnected world. Our international studies programs are
              designed to help individuals broaden their horizons, gain
              cross-cultural competence, and thrive in a diverse global
              landscape. We facilitate a wide range of opportunities, including
              study abroad programs, language immersion experiences, and
              cross-cultural training. Our goal is to equip students and
              professionals with the skills and insights needed to navigate the
              complexities of an international environment, fostering greater
              understanding and collaboration on a global scale.
            </p>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded"
            style={{ maxHeight: "300px" }}
            src="./image/international.png"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EducationAndTraining;
