import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import HomeScreen from "./screens/HomeScreen";
import Header from "./componants/Header";
import Footer from "./componants/Footer";
import AboutUs from "./screens/AboutUs";
import Contact from "./screens/Contact";
import ProfessionalService from "./screens/ProfessionalService";
import ProfessionalStudent from "./screens/ProfessionalStudent";
import CareServices from "./screens/CareServices";
import RecruitmentAgency from "./screens/RecruitmentAgency";
import ConsultancyServices from "./screens/ConsultancyServices";
import ChurchAndCharity from "./screens/ChurchAndCharity";
import EducationAndTraining from "./screens/EducationAndTraining";


function App() {
  return (
    <>
      <Router>
        <Header />
        <main className="main" style={{ minHeight: "85vh" }}>
          <Container fluid className="px-0">
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/aboutUs" element={<AboutUs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/professionalStudentStudies" element={<ProfessionalStudent/>}/>
              <Route path="/professionalStudentService" element={<ProfessionalService />} />

              <Route path="/careServices" element={<CareServices />} />
              <Route path="/recruitmentAgency" element={<RecruitmentAgency />} />
              <Route path="/consultancyServices" element={<ConsultancyServices />} />
              <Route path="/churchCharity" element={<ChurchAndCharity />} />

              <Route path="/educationTraining" element={<EducationAndTraining />} />
            </Routes>
          </Container>
        </main>
        <Footer />
      </Router>
    </>
  );
}

export default App;
