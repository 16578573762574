import React from "react";
import { Carousel, Card, Button, Nav } from "react-bootstrap";

const Navbar = () => {
  return (
    <>
      <Carousel indicators="" className="mt-5">
        <Carousel.Item>
          <Card>
            <Card.Img
              cascade
              className="img-fluid"
              style={{ maxHeight: "90vh", objectFit: "cover" }}
              src="/image/navbar1.jpg"
            />
          </Card>
          <Carousel.Caption className="">
            <h1 className="mb-5">Welcome to GAPS</h1>
            {/* <Button variant="success" style={{ fontWeight: "bold" }}>
              <Nav.Link style={{ color: "white" }} href="#services">
                Get Started
              </Nav.Link>
            </Button> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Card>
            <Card.Img
              cascade
              className="img-fluid"
              style={{ maxHeight: "90vh", objectFit: "cover" }}
              src="/image/navbar2.jpg"
            />
          </Card>
          <Carousel.Caption>
            <h1 className="mb-5">
              Opportunity to Build Your Career In The Top Universities
            </h1>
            {/* <Button variant="success" style={{ fontWeight: "bold" }}>
              <Nav.Link style={{ color: "white" }} href="#services">
                Get Started
              </Nav.Link>
            </Button> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Card>
            <Card.Img
              cascade
              className="img-fluid"
              style={{ maxHeight: "90vh", objectFit: "cover" }}
              src="/image/navbar3.jpg"
            />
          </Card>
          <Carousel.Caption>
            <h1 className="mb-5">Experience The Best Service With Us</h1>
            {/* <Button variant="success" style={{ fontWeight: "bold" }}>
              <Nav.Link style={{ color: "white" }} href="#services">
                Get Started
              </Nav.Link>
            </Button> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default Navbar;
