import React from 'react'
import { Container, Row, Col, Card } from "react-bootstrap";


const ProfessionalService = () => {
  return (
      <Container className="mt-5 pt-5">
          <Row className="my-4">
              <h3 className="text-center mb-4">Professional Student Services</h3>
              <p className="mt-2 mb-5" style={{ textAlign: "justify" }}>
                  Sed ut perspiciaatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
                  ab illo inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Nemo enim ipsam voluptatem quia voluptas Sed ut
                  perspiciaatis unde omnis iste natus error sit voluptatem accusantium
                  doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
                  inventore veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Nemo enim ipsam voluptatem quia voluptas.
              </p>
          </Row>
          <Row className="mt-4 mb-5">
              <Col xs={12} md={6} lg={6}>
                  <Card.Img
                      style={{ maxHeight: "300px" }}
                      src="https://learning-zone.github.io/website-templates/above-educational-bootstrap-responsive-template/img/section-image-1.png"
                  />
              </Col>
              <Col xs={12} md={6} lg={6}>
                  <Row>
                      <p style={{ textAlign: "justify" }}>
                          Lorem ipsum dolor sit amet, cadipisicing sit amet, consectetur
                          adipisicing elit. Atque sed, quidem quis praesentium, ut unde fuga
                          error commodi architecto, laudantium culpa tenetur at id, beatae
                          pet. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                          adipisicing sit amet, consectetur adipisicing elit. Atque sed,
                          quidem quis praesentium,m deserunt.
                      </p>
                  </Row>
              </Col>
          </Row>

          <Row className="mt-4 mb-5">
              <Col xs={12} md={6} lg={6}>
                  <Row>
                      <p style={{ textAlign: "justify" }}>
                          Lorem ipsum dolor sit amet, cadipisicing sit amet, consectetur
                          adipisicing elit. Atque sed, quidem quis praesentium, ut unde fuga
                          error commodi architecto, laudantium culpa tenetur at id, beatae
                          pet. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                          adipisicing sit amet, consectetur adipisicing elit. Atque sed,
                          quidem quis praesentium,m deserunt.
                      </p>
                  </Row>
              </Col>
              <Col xs={12} md={6} lg={6}>
                  <Card.Img
                      style={{ maxHeight: "300px" }}
                      src="https://learning-zone.github.io/website-templates/above-educational-bootstrap-responsive-template/img/section-image-1.png"
                  />
              </Col>
          </Row>

          <Row className="mt-4 mb-5">
              <Col xs={12} md={6} lg={6}>
                  <Card.Img
                      style={{ maxHeight: "300px" }}
                      src="https://learning-zone.github.io/website-templates/above-educational-bootstrap-responsive-template/img/section-image-1.png"
                  />
              </Col>
              <Col xs={12} md={6} lg={6}>
                  <Row>
                      <p style={{ textAlign: "justify" }}>
                          Lorem ipsum dolor sit amet, cadipisicing sit amet, consectetur
                          adipisicing elit. Atque sed, quidem quis praesentium, ut unde fuga
                          error commodi architecto, laudantium culpa tenetur at id, beatae
                          pet. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                          adipisicing sit amet, consectetur adipisicing elit. Atque sed,
                          quidem quis praesentium,m deserunt.
                      </p>
                  </Row>
              </Col>
          </Row>

          <Row className="mt-4 mb-5">
              <Col xs={12} md={6} lg={6}>
                  <Row>
                      <p style={{ textAlign: "justify" }}>
                          Lorem ipsum dolor sit amet, cadipisicing sit amet, consectetur
                          adipisicing elit. Atque sed, quidem quis praesentium, ut unde fuga
                          error commodi architecto, laudantium culpa tenetur at id, beatae
                          pet. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                          adipisicing sit amet, consectetur adipisicing elit. Atque sed,
                          quidem quis praesentium,m deserunt.
                      </p>
                  </Row>
              </Col>
              <Col xs={12} md={6} lg={6}>
                  <Card.Img
                      style={{ maxHeight: "300px" }}
                      src="https://learning-zone.github.io/website-templates/above-educational-bootstrap-responsive-template/img/section-image-1.png"
                  />
              </Col>
          </Row>

      </Container>
  )
}

export default ProfessionalService