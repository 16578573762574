import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const ConsultancyServices = () => {
  return (
    <Container className="mt-5 pt-5">
      <Row className="my-4">
        <h2 className="text-center mb-4">Consultancy Services</h2>
        <p className="mt-2 mb-5" style={{ textAlign: "justify" }}>
          We believe in the power of informed decisions and strategic guidance.
          Our "Consultancy Services" division is dedicated to helping businesses
          and individuals unlock their full potential, navigate challenges, and
          achieve success through expert consultation and support.
          <br />
          Explore our wide range of consultancy services, each meticulously
          designed to address specific needs in the ever-changing landscape of
          business and leadership. Whether you're seeking innovative business
          initiatives, transformative leadership development, operational
          support, or specialized consulting, our team of seasoned professionals
          is here to guide you.
          <br />
        </p>
      </Row>
      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/initiative.png"
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Business Initiative</h3>
            <p style={{ textAlign: "justify" }}>
              In the fast-paced world of business, innovation and strategic
              vision are paramount. Our "Business Initiative Consultancy"
              services are tailored to empower your organization with the
              agility and vision needed to thrive.
              <br />
              We understand that every business faces unique challenges and
              opportunities. Our team of seasoned consultants works closely with
              you to identify untapped potential, develop cutting-edge
              strategies, and drive forward-thinking initiatives that fuel
              growth. Whether you're launching a startup, seeking to revitalize
              an established enterprise, or exploring new markets, our expertise
              is your competitive advantage.
            </p>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Leadership</h3>
            <p style={{ textAlign: "justify" }}>
              In the ever-evolving landscape of business, strong leadership is
              the compass that guides organizations toward success. Our
              "Leadership Consultancy" service at [College Name] is dedicated to
              nurturing and developing effective leaders who can inspire,
              innovate, and drive positive change.
              <br />
              We understand that leadership isn't one-size-fits-all. Our
              seasoned consultants work closely with you to identify your unique
              leadership strengths and opportunities for growth. Through
              personalized coaching, mentorship, and strategic guidance, we
              empower you to become a dynamic and influential leader.
              <br />
              Whether you're an aspiring executive, a seasoned manager, or a
              business owner seeking to enhance your leadership skills, our
              consultancy service is designed to elevate your leadership
              potential.
            </p>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/leadership.png"
          />
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/support.png"
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Support</h3>
            <p style={{ textAlign: "justify" }}>
              In the ever-evolving landscape of business and leadership,
              navigating challenges and seizing opportunities can be a complex
              endeavor. Our "Support Consultancy Services" are designed to be
              your steadfast companion on this journey.
              <br />
              Whether you're facing operational hurdles, seeking strategic
              guidance, or simply need expert advice to overcome obstacles, our
              dedicated team of consultants is here to provide the support you
              need.
              <br />
              Our support services encompass a wide range of areas, from
              streamlining processes and optimizing operations to providing
              tailored solutions for unique challenges. With a focus on your
              goals and aspirations, we work alongside you to turn challenges
              into opportunities, ensuring your path to success remains clear
              and achievable.
            </p>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Other Service</h3>
            <p style={{ textAlign: "justify" }}>
              Whether you require niche consulting, innovative problem-solving,
              or assistance with unique challenges, our team of seasoned experts
              is ready to collaborate with you. We thrive on the complexity of
              your unique circumstances, finding creative approaches to address
              your specific requirements.
              <br />
              Discover how our Consultancy Services can be your strategic
              advantage, offering the guidance and expertise necessary to
              navigate the intricacies of your industry and achieve exceptional
              outcomes.
            </p>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/service.png"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ConsultancyServices;
