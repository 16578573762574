import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const AboutUs = () => {
  return (
    <Container className="mt-5 pt-5">
      <Row className="my-4">
        <h3 className="text-center mb-4">About Our Team and Company</h3>
        <p className="mt-2 mb-5" style={{ textAlign: "justify" }}>
          We provide multiple services to our clients. We are a team of highly
          qualified and experienced professionals. We provide consultancy
          services as well as training and education services. We have already
          trained a large number of candidates for specific purpose to fulfill
          their dreams. Additionally, We provide recruitment services to our
          clients and help them find the best candidates for their jobs
          following the candidates Leadership and management skills. Also we run
          a charity service for the people who are in need. We provide them with
          food, clothes and other necessary things.
          <br />
          Come and join us to make a difference in your life.
        </p>
      </Row>
      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6} className='mb-5'>
          <Card.Img
            className="rounded shadow"
            style={{ height: "350px" }}
            src="/image/aboutus.jpg"
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Row>
            <p style={{ textAlign: "justify" }}>
              Our comprehensive suite of services that cater to diverse needs
              and aspirations. Our team of seasoned professionals is dedicated
              to empowering individuals and organizations across various
              domains. With a strong emphasis on consultancy, training,
              education, recruitment, and philanthropy, we are committed to
              making a positive impact in our community and beyond.
            </p>
          </Row>

          <Row>
            <p>
              👉 Strategic Guidance, Business Initiatives, Leadership and
              Support as part of the Consultancy Services, <br />
              👉 Extensive Training for both Local or International students for
              Educational and Professional purposes, <br />
              👉 Highly qualified recruitment based on the client companies and
              on the candidates management potential, <br />
              👉 Care Services for Elderly as well as Adults and Disabled
              people, <br />
              👉 Food, Clothing, Support and Professional Counselling and other
              necessary things for the people who are in need as part of our
              Charity Services. <br />
            </p>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3 mb-5">
        
        <Col xs={12} md={6} lg={6}>
          <h4>Why Choose Us?</h4>
          <p className="my-3" style={{ textAlign: "justify" }}>
            We stand out as your ideal partner because of our unwavering
            commitment to excellence and community impact. With a team of highly
            qualified experts who bring a wealth of industry experience, we
            offer a comprehensive suite of services, including consultancy,
            training, education, recruitment, and charitable initiatives. What
            sets us apart is our personalized, client-centric approach, where we
            prioritize understanding your unique goals and challenges. We have a
            proven track record of delivering results, be it in training
            candidates, providing effective consultancy, or assisting in
            recruitment. Moreover, our focus on identifying candidates with
            leadership potential goes beyond traditional recruitment practices.
            As a responsible member of the community, we engage in charity
            initiatives to give back and support those in need. Our ethical,
            transparent practices, long-term partnership mindset, and commitment
            to innovation ensure that we are not just a service provider but a
            dedicated ally on your journey to success. Choose us for a partner
            that combines expertise, integrity, and a genuine passion for your
            success.
          </p>
        </Col>
        <Col xs={12} md={6} lg={6} className='mb-5'>
          <h4>Our Works</h4>
          <p className="my-3" style={{ textAlign: "justify" }}>
            👉 Consulted for Supporting Organizations : 20+ <br /><br />
            👉 Recruitment and Job Placement provided : 15+ <br /><br />
            👉 Training provided for various fields : Students,<br /> &nbsp; &nbsp; &nbsp; Administrations,
            Leadership & Management,<br /> &nbsp; &nbsp; &nbsp; Retail Business, Ware House Staffs <br /><br />
            👉 Total Training Provided : 1000+ <br />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
