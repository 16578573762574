import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const OurServices = () => {
  return (
    <Container className="my-5 py-5">
      <h1 className="text-center fw-bold py-3">Services We Offer</h1>
      <h4 className="text-center text-muted mb-4">
        Explore Our Comprehensive Offerings
      </h4>
      <p className="text-center py-3">
        Discover the range of services we provide to meet your needs. From
        expert consulting to top-notch solutions, we're here to help you
        succeed. Whether you're looking for strategic guidance, innovative
        solutions, or expert support, we have you covered. Explore our offerings
        and let's work together to achieve your goals.
      </p>

      <Row className="d-flex justify-content-center mt-5">
        <Col xs={12} md={6} lg={4}>
          <Card className="my-3 rounded" style={{ minHeight: "23vh", backgroundColor: "#e6e6e6" }}>
            {/* <Card.Img
              style={{ maxHeight: "300px" }}
              src="https://img.freepik.com/free-vector/nature-scene-with-river-hills-forest-mountain-landscape-flat-cartoon-style-illustration_1150-37326.jpg?w=2000"
            /> */}
            <Card.Header as="h5" className="text-center p-3 border-0 fw-bold">
              Care for Elderly People
            </Card.Header>
            <Card.Text className="py-4 px-3" style={{ textAlign: "justify" }}>
              We are dedicated to meeting the unique needs of the elderly,
              whether it's assistance with daily activities, medication
              management, companionship, or specialized healthcare.
            </Card.Text>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <Card className="my-3 rounded" style={{ minHeight: "23vh", backgroundColor: "#e6e6e6" }}>
            {/* <Card.Img
              style={{ maxHeight: "300px" }}
              src="https://img.freepik.com/free-vector/nature-scene-with-river-hills-forest-mountain-landscape-flat-cartoon-style-illustration_1150-37326.jpg?w=2000"
            /> */}
            <Card.Header as="h5" className="text-center p-3 border-0 fw-bold">
              Care for Disabled
            </Card.Header>
            <Card.Text className="py-4 px-3" style={{ textAlign: "justify" }}>
              We are committed to providing compassionate care that promotes
              physical, emotional, and social well-being for individuals with
              disabilities.
            </Card.Text>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <Card className="my-3 rounded" style={{ minHeight: "23vh", backgroundColor: "#e6e6e6" }}>
            <Card.Header as="h5" className="text-center p-3 border-0 fw-bold">
              Management Services
            </Card.Header>
            <Card.Text className="py-4 px-3" style={{ textAlign: "justify" }}>
              From industry insights, rigorous evaluation, to commitment to
              understand your organization's goals. We're dedicated to finding
              candidates who'll meet the technical qualifications align with
              your values and vision.
            </Card.Text>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <Card className="my-3 rounded" style={{ minHeight: "23vh", backgroundColor: "#e6e6e6" }}>
            <Card.Header as="h5" className="text-center p-3 border-0 fw-bold">
              Support Homeless
            </Card.Header>
            <Card.Text className="py-4 px-3" style={{ textAlign: "justify" }}>
              We believe in offering holistic assistance that includes safe
              shelter, nutritious meals, access to healthcare, and opportunities
              for skill development.
            </Card.Text>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <Card className="my-3 rounded" style={{ minHeight: "23vh", backgroundColor: "#e6e6e6" }}>
            <Card.Header as="h5" className="text-center p-3 border-0 fw-bold">
              Advice & Counselling
            </Card.Header>
            <Card.Text className="py-4 px-3" style={{ textAlign: "justify" }}>
              For mental health issues, managing personal crises, or making
              important life decisions, or individuals seeking guidance we offer
              professional advice and counseling services to overcome obstacles
              and lead fulfilling lives.
            </Card.Text>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <Card className="my-3 rounded" style={{ minHeight: "23vh", backgroundColor: "#e6e6e6" }}>
            <Card.Header as="h5" className="text-center p-3 border-0 fw-bold">
              Graduate and Professional Studies for All
            </Card.Header>
            <Card.Text className="py-4 px-3" style={{ textAlign: "justify" }}>
              We are committed to providing comprehensive support and resources
              to help individuals excel in their educational and professional
              pursuits for International and Local Students.
            </Card.Text>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OurServices;
