import React from "react";
import { Carousel, Container, Row, Col, Card } from "react-bootstrap";

const Quotes = () => {
  return (
    <div>
      <Carousel
        className="d-flex align-items-center my-5"
        indicators={false}
        style={{ minHeight: "22vh", backgroundColor: "#e6e6e6" }}
      >
        <Carousel.Item>
          <Container>
            <Row>
              <h6 className="fst-italic text-center">
                Blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate non
                provident, similique sunt in culpa qui officia deserunt mollitia
                animi.
              </h6>
            </Row>

            <Row>
              <h6 className="my-3 text-center">--Sakib Patwary--</h6>
            </Row>
          </Container>
        </Carousel.Item>

        <Carousel.Item>
          <Container>
            <Row>
              <h6 className="fst-italic text-center">
                Blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate non
                provident, similique sunt in culpa qui officia deserunt mollitia
                animi.
              </h6>
            </Row>

            <Row>
              <h6 className="my-3 text-center">--Tanzeem Rahat--</h6>
            </Row>
          </Container>
        </Carousel.Item>

        <Carousel.Item>
          <Container>
            <Row>
              <h6 className="fst-italic text-center">
                Blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate non
                provident, similique sunt in culpa qui officia deserunt mollitia
                animi.
              </h6>
            </Row>

            <Row>
              <h6 className="my-3 text-center">--Pradip Kumer--</h6>
            </Row>
          </Container>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Quotes;
