import React from "react";
import { Container, Navbar, Col, Row, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Footer = () => {
  return (
    <>
      <Navbar bg="info" style={{ color: "white" }}>
        <Container>
          <Col>
            <Row className="my-3 mb-3 text-center">
              <Col xs={12} md={4} lg={4}>
                <h5>Contact</h5>
                <p>
                  Phone: +44 2035817258 <br />
                  Mail: info@gapsinternational.co.uk
                </p>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <h5>Address</h5>
                <p>
                  Graduate And Professional Services (GAPS).
                  <br />
                  20C(B) Selsdon Road
                  <br />
                  Croydon, London
                  <br />
                  United Kingdom
                  <br />
                  CR2 6PA
                </p>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <h5>Quick Links</h5>
                <p>
                  <LinkContainer to="/careServices">
                    <Nav.Link>Care Service</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/recruitmentAgency">
                    <Nav.Link>Recruitement Agency</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/consultancyServices">
                    <Nav.Link>Consultancy Services</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/churchCharity">
                    <Nav.Link>Church & Charity Services</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/educationTraining">
                    <Nav.Link>Education & Training</Nav.Link>
                  </LinkContainer>
                </p>

              </Col>
            </Row>

            <Row
              className="pt-3 d-flex justify-content-center text-center"
              style={{ borderTop: "1px solid" }}
            >
              Copyright &copy; 2023 GAPS Consultancy. All Rights Reserved.
            </Row>
            <Row className="d-flex justify-content-center text-center">
              <p>
                Designed & Developed by
                <a
                  target="blank"
                  href="https://www.tgitbangladesh.com"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  TGIT
                </a>
              </p>
            </Row>
          </Col>
        </Container>
      </Navbar>
    </>
  );
};

export default Footer;
