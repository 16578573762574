import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const CareServices = () => {
  return (
    <Container className="mt-5 pt-5">
      <Row className="my-4">
        <h1 className="text-center mb-4">Care Services</h1>
        <p className="mt-2 mb-5" style={{ textAlign: "justify" }}>
          Welcome to Our Care Services. We understand that care is a deeply
          personal and essential aspect of our lives. Our dedicated team is
          committed to providing compassionate care services tailored to the
          unique needs of individuals and communities across the UK. Whether
          it's support for the elderly, adults, individuals with disabilities,
          or those with specific needs, our comprehensive care services are
          designed to enhance well-being, foster independence, and improve
          quality of life.
          <br />
          Discover the care services that matter most to you, and let us be your
          trusted partner on the journey to a happier, healthier life.
        </p>
      </Row>
      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/elderly.png"
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Care Services for Elderly</h3>
            <p style={{ textAlign: "justify" }}>
              As we age, the need for specialized care and support becomes
              increasingly important. Our "Care Services for Elderly" program is
              thoughtfully designed to provide seniors with the care,
              companionship, and assistance they deserve.
              <br />
              Our experienced caregivers are dedicated to meeting the unique
              needs of the elderly, whether it's assistance with daily
              activities, medication management, companionship, or specialized
              healthcare. We understand the importance of preserving
              independence while ensuring safety and well-being.
            </p>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Care Services for Adults</h3>
            <p style={{ textAlign: "justify" }}>
              we recognize that adulthood comes with unique challenges and
              needs. Our Care Services for Adults are thoughtfully designed to
              provide support, care, and assistance to individuals in this
              important stage of life. Whether you or your loved one requires
              help with daily tasks, companionship, or specialized care, our
              dedicated team is here to ensure a fulfilling and comfortable
              adult life.
              <br />
              Our range of services includes personalized care plans that cater
              to a variety of needs, from maintaining independence to managing
              health conditions. We are committed to promoting physical,
              emotional, and social well-being, fostering an environment where
              adults can thrive and enjoy life to the fullest.
            </p>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/adults.png"
          />
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/disabled.png"
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Care Services for Disabled</h3>
            <p style={{ textAlign: "justify" }}>
              We recognize the unique challenges faced by individuals with
              disabilities, and we're here to provide specialized care services
              that empower and support them. Our dedicated team of caregivers is
              trained to offer personalized assistance, fostering independence
              and enhancing the overall quality of life for those with
              disabilities.
              <br />
              Our Care Services for Disabled are designed to meet the unique
              needs of each individual, whether it's assistance with daily
              activities, companionship, or specialized care. We are committed
              to providing compassionate care that promotes physical, emotional,
              and social well-being.
              <br />
              Whether you or your loved one require assistance with mobility,
              personal care, medication management, or simply a friendly face to
              brighten your day, our caring professionals are here to make life
              more comfortable and enjoyable.
            </p>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4 mb-5">
        <Col xs={12} md={6} lg={6}>
          <Row>
            <h3 className="mb-2">Care Services for Individual Needs</h3>
            <p style={{ textAlign: "justify" }}>
              As we all know that, every person is unique, and their care
              requirements can vary significantly. Our "Care Services for
              Individual Needs" are specially tailored to address the diverse
              and specific requirements of our clients. Whether you or your
              loved one have unique medical conditions, preferences, or
              circumstances, our experienced caregivers work closely with you to
              create a personalized care plan.
              <br />
              Our flexible approach ensures that you receive the support you
              need, precisely when you need it. From specialized medical care to
              assistance with daily activities, our dedicated team is here to
              empower you to live life to the fullest. We believe that every
              individual deserves care that respects their individuality and
              promotes their well-being.
              <br />
            </p>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Card.Img
            className="rounded shadow"
            style={{ maxHeight: "300px" }}
            src="./image/needs.png"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CareServices;
